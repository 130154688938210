<template>
	<div v-loading="loading">
		<div class="item-block">
			<div class="payment-detail">
				<div class="payment-media">
					<el-row>
						<el-col :span="4">
							<div class="media-left"><i class="el-icon-circle-check ns-text-color"></i></div>
						</el-col>

						<el-col :span="16">
							<div class="media-body">
								<el-row>
									<el-col :span="12">
										<div class="payment-text">{{$lang('您的订单已提交成功，正在等待处理！')}}</div>
										<div>
											<span>{{$lang('应付金额')}}：</span>
											<span class="payment-money ns-text-color">NZD{{ payInfo.pay_money }}</span>
										</div>
									</el-col>
									<el-col :span="12"></el-col>
								</el-row>
							</div>
						</el-col>

						<el-col :span="4">
							<div class="media-right">
								<div class="el-button--text"
									@click="orderOpen ? (orderOpen = false) : (orderOpen = true)">
									{{$lang('订单信息')}}
									<i :class="orderOpen ? 'rotate' : ''" class="el-icon-arrow-down"></i>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>

				<div class="order-info" v-if="orderOpen">
					<el-row>
						<el-col :span="4" class="order-info-left"></el-col>
						<el-col :span="20">
							<div class="line"></div>
							<div class="order-item">
								<div class="item-label">{{$lang('交易单号')}}：</div>
								<div class="item-value">{{ payInfo.out_trade_no }}</div>
							</div>
							<div class="order-item">
								<div class="item-label">{{$lang('订单内容')}}：</div>
								<div class="item-value">{{$trans(payInfo.pay_detail) }}</div>
							</div>
							<div class="order-item">
								<div class="item-label">{{$lang('订单金额')}}：</div>
								<div class="item-value">NZD{{ payInfo.pay_money }}</div>
							</div>
							<div class="order-item">
								<div class="item-label">{{$lang('创建时间')}}：</div>
								<div class="item-value">{{ $timeStampTurnTime(payInfo.create_time) }}</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>

		<div class="item-block">
			<div class="block-text">{{$lang("支付方式")}}</div>
			<div class="pay-type-list" v-if="payTypeList.length">
				<div class="pay-type-item" v-for="(item, index) in payTypeList" :key="index"
					:class="payIndex == index ? 'active' : ''" @click="payIndex = index">
					{{$trans(item.name) }}
				</div>
				<div class="clear"></div>
			</div>
			<div class="no-pay-type" v-else>
				<p>{{$lang('商家未配置支付方式')}}</p>
			</div>
		</div>

		<div class="item-block" v-if="payTypeList.length">
			<div class="order-submit">
				<el-button type="primary" class="el-button--primary" @click="pay">{{$lang('立即支付')}}</el-button>
			</div>
			<div class="clear"></div>
		</div>

		<el-dialog title="请确认支付是否完成" :visible.sync="dialogVisible" width="23%" top="30vh" class="confirm-pay-wrap">
			<div class="info-wrap">
				<i class="el-message-box__status el-icon-warning"></i>
				<span>完成支付前请根据您的情况点击下面的按钮</span>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="goIndex" size="small">返回首页</el-button>
				<el-button type="primary" @click="goOrderList" size="small">已完成支付</el-button>
			</span>
		</el-dialog>

		<!-- 微信支付弹框 -->
		<el-dialog title="请用微信扫码支付" :visible.sync="openQrcode" width="300px" center>
			<div class="wechatpay-box"><img :src="payQrcode" /></div>
		</el-dialog>
		<!-- 转账支付加载弹窗 -->
			<el-dialog
				:title="$lang('详细信息')"
				:visible.sync="uploadVisible"
				width="30%"
				class="upload-zz"
				style="z-index:999"
				@close="uploadVisible=false;zzImg='';imgUrl='';show_transfer_step=0"
				>
				<div class="up-load--text order-list">
					<span class="upload-memo">{{$lang('转账图片')}}：</span>
					<div @mouseenter="isShow =true" @mouseleave="isShow = false" style="position:relative">
						<span v-show="isShow" v-if="zzImg" class="shade">
							<i class="el-icon-delete" @click.stop="delet" />
							<i class="el-icon-zoom-in" @click.stop="preview" />
						</span>	
						<el-upload :action="uploadActionUrl" :show-file-list="false" :on-success="handleAvatarSuccess" class="upload">
							<img v-if="zzImg" :src="zzImg" alt="" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>	
					</div>
					
				</div>
				<div v-if="tranferinfo.ztype==1">
				<div class="up-load--text">
					<span class="upload-memo">{{$lang('银行开户名')}}：</span>
					<span>{{tranferinfo.yhkhm}}</span>
				</div>
				
				<div class="up-load--text">
					<span class="upload-memo">{{$lang('银行账号')}}：</span>
					<span>{{tranferinfo.yhkh}}</span>
				</div>				
				
				<div class="up-load--text">
					<span class="upload-memo">{{$lang('开户支行')}}：</span>
					<span>{{tranferinfo.zhmc}}</span>
				</div>	
				</div>
				<div v-if="tranferinfo.ztype==2">
					<div class="up-load--text">
						<span>{{$trans(tranferinfo.sdesc)}}</span>
					</div>
					<div class="wechatpay-box" style="width:98%;"><img :src="ewm" /></div>
				</div>
				
				
				<span slot="footer" class="dialog-footer">
					<el-button size="medium" type="primary" @click="pay">{{$lang('确认支付')}}</el-button>
				</span>
			</el-dialog>		
	
	</div>
</template>

<script>
	import {
		getPayInfo,
		getPayType,
		checkPayStatus,
		getTransferInfo,
		pay,
		pay2,
	} from '@/api/pay';
    import Config from '@/utils/config';
	export default {
		name: 'pay',
		components: {},
		data: () => {
			return {
				orderOpen: false,
				outTradeNo: '',
				payInfo: {
					pay_money: 0
				},
				payIndex: 0,
				payTypeList: [{
						name: '支付宝支付',
						icon: 'iconzhifubaozhifu-',
						type: 'alipay'
					},
					{
						name: '银行卡支付',
						icon: 'iconzhifubaozhifu-',
						type: 'stripe'
					},
					{
						name: '微信支付',
						icon: 'iconweixinzhifu',
						type: 'wechatpay'
					},
					{
						name: '转账支付',
						icon: 'iconweixinzhifu',
						type: 'transfer'
					}
				],
				payUrl: '',
				uploadActionUrl: Config.baseUrl + '/api/upload/headimg',
				timer: null,
				payQrcode: '',
				openQrcode: false,
				loading: true,
				test: null,
				dialogVisible: false,
				uploadVisible:false,
				zzImg:'',
				imgUrl:"",
				show_transfer_step:0,
				tranferinfo:{},
				ewm:""
			};
		},
		created() {
			if (!this.$route.query.code) {
				this.$router.push({
					path: '/'
				});
				return;
			}
			this.outTradeNo = this.$route.query.code;
			this.getPayInfo();
			this.getPayType();
			this.checkPayStatus();
			this.getTransferInfo();
		},
		methods: {
			getPayInfo() {
				getPayInfo({
						out_trade_no: this.outTradeNo,
						forceLogin: true
					})
					.then(res => {
						const {
							code,
							message,
							data
						} = res;
						if (data) {
							this.payInfo = res.data;
						}
						this.loading = false;
					})
					.catch(err => {
						this.loading = false;
						this.$message.error({
							message: err.message,
							duration: 2000,
							onClose: () => {
								this.$router.push({
									path: '/member/order_list'
								});
							}
						});
					});
			},
			getPayType() {
				getPayType({})
					.then(res => {
						const {
							code,
							message,
							data
						} = res;
						if (code >= 0) {
							if (res.data.pay_type == '') {
								this.payTypeList = [];
							} else {
								this.payTypeList.forEach((val, key) => {
									if (res.data.pay_type.indexOf(val.type) == -1) {
										this.payTypeList.splice(key, 1);
									}
								});
							}
						}
					})
					.catch(err => {
						this.$message.error(err.message);
					});
			},
			
			
			getTransferInfo() {
				getTransferInfo({})
					.then(res => {
				    
					})
					.catch(err => {
				       this.tranferinfo=err;
					   this.ewm=Config.baseUrl+"/"+err.ewm;
					});
			},			

			checkPayStatus() {
				this.timer = setInterval(() => {
					checkPayStatus({
							out_trade_no: this.outTradeNo
						})
						.then(res => {
							const {
								code,
								message,
								data
							} = res;
							if (code >= 0) {
								if (code == 0) {
									if (data.pay_status == 2) {
										clearInterval(this.timer);
										this.dialogVisible = false;
										this.$router.push({
											path: '/result?code=' + this.payInfo.out_trade_no
										});
									}
								} else {
									clearInterval(this.timer);
								}
							}
						})
						.catch(err => {
							clearInterval(this.timer);
							this.$router.push({
								path: '/'
							});
						});
				}, 1000);
			},
			pay() {
				var payType = this.payTypeList[this.payIndex];
				if (!payType) return;
				if(payType.type=='transfer' && this.show_transfer_step==0){
				  this.uploadVisible=true;
				  this.show_transfer_step=1;
				  return false;	
				}
				if(this.show_transfer_step==1 && this.imgUrl==""){
					this.$message({
						message: '请上传转账支付截图',
						type: 'warning'
					});
					return false;
				}
				
				if (payType.type == 'stripe') {
					var newWindow = window.open();
					console.log(this.$config);
					pay2({
							out_trade_no: this.payInfo.out_trade_no,
							success_url: this.$config.baseUrl + '/member/order_list',
							cancel_url: this.$config.baseUrl + '/member/order_list',
							currency: '',
						})
						.then(res => {
							const {
								code,
								message,
								data
							} = res;
							if (code >= 0) {
								switch (payType.type) {
									case 'stripe':
										this.payUrl = res.data.data;
										newWindow.location.href = this.payUrl;
										this.open();
										break;
									case 'alipay':
										this.payUrl = res.data.data;
										newWindow.location.href = this.payUrl;
										this.open();
										break;
									case 'wechatpay':
										this.payQrcode = res.data.qrcode;
										this.openQrcode = true;
										break;
								}
							} else {
								this.$message({
									message: message,
									type: 'warning'
								});
							}
						})
						.catch(err => {
							this.$message.error(err.message);
						});
				} else {
					pay({
							out_trade_no: this.payInfo.out_trade_no,
							pay_type: payType.type,
							app_type: 'pc',
							uploadImg:Config.baseUrl+"/"+this.imgUrl,
						})
						.then(res => {
							const {
								code,
								message,
								data
							} = res;
							if (code >= 0) {
								switch (payType.type) {
									case 'alipay':
										this.payUrl = res.data.data;
										newWindow.location.href = this.payUrl;
										this.open();
										break;
									case 'wechatpay':
										this.payQrcode = res.data.qrcode;
										this.openQrcode = true;
										break;
									case 'transfer':
										this.$router.push({
											path: '/member/order_list'
										});
										break;		
								}
							} else {
								this.$message({
									message: message,
									type: 'warning'
								});
							}
						})
						.catch(err => {
							this.$message.error(err.message);
						});
				}
			},
			open() {
				this.dialogVisible = true;
			},
			goIndex() {
				clearInterval(this.timer);
				this.dialogVisible = false;
				this.$router.push({
					path: '/'
				});
			},
			goOrderList() {
				clearInterval(this.timer);
				this.dialogVisible = false;
				this.$router.push({
					path: '/member/order_list'
				});
			},
			handleAvatarSuccess(res, file) {
				this.imgUrl = res.data.pic_path;
				this.zzImg = URL.createObjectURL(file.raw);
			},
			
		}
	};
</script>

<style lang="scss" scoped>
	.clear {
		clear: both;
	}

	.item-block {
		padding: 0 15px 1px;
		margin: 10px 0;
		border-radius: 0;
		border: none;
		background: #ffffff;

		.block-text {
			border-color: #eeeeee;
			color: $ns-text-color-black;
			padding: 7px 0;
			border-bottom: 1px;
		}
	}

	.media-left {
		text-align: center;

		i {
			font-size: 65px;
		}
	}

	.payment-detail {
		padding: 30px 0;
		transition: 2s;
	}

	.media-right {
		text-align: center;
		line-height: 65px;
		cursor: pointer;

		i.rotate {
			transform: rotate(180deg);
			transition: 0.3s;
		}
	}

	.payment-text {
		font-size: 20px;
	}

	.payment-time {
		font-size: 12px;
		line-height: 65px;
		color: #999;
	}

	//支付方式
	.order-submit {
		float: right;
		padding: 10px;
	}

	.pay-type-list {
		padding: 20px 0;
	}

	.pay-type-item {
		display: inline-block;
		border: 2px solid #eeeeee;
		padding: 5px 20px;
		margin-right: 20px;
		cursor: pointer;
	}

	.pay-type-item.active {
		border-color: $base-color;
	}

	.mobile-wrap {
		width: 300px;
	}

	.order-info {
		.order-item {
			padding: 1px 0;

			.item-label {
				display: inline-block;
				width: 100px;
			}

			.item-value {
				display: inline-block;
			}
		}

		.line {
			width: 100%;
			height: 1px;
			background: #f2f2f2;
			margin: 20px 0 10px 0;
		}

		.order-info-left {
			height: 1px;
		}
	}

	.wechatpay-box {
		text-align: center;

		img {
			width: 80%;
		}
	}

	.confirm-pay-wrap {
		.el-dialog__body {
			padding: 10px 15px;
		}

		.info-wrap {
			i {
				position: initial;
				vertical-align: middle;
				transform: initial;
			}

			span {
				vertical-align: middle;
				padding: 0 10px;
			}
		}
	}
</style>
<style lang="scss">
	.confirm-pay-wrap {
		.el-dialog__body {
			padding: 10px 15px;
		}

		.el-dialog__footer {
			padding-top: 0;
			padding-bottom: 10px;
		}
	}
	
.up-load--text{
	display: flex;
	
}
.order-list {
	  ::v-deep .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	::v-deep .el-image-viewer__wrapper{
		z-index: 99999 !important;
	}
	::v-deep .el-image-viewer__close{
		color: #fff !important;
	}
	  ::v-deep .el-upload:hover {
		border-color: #409EFF;
	}
	.shade{
		position: absolute;
		width: 160px;
		height: 160px;
		text-align: center;
		background: rgba($color: #000000, $alpha: .3);
		z-index: 99999;
		
	}
	::v-deep .shade i{
			width: 50%;
			display: inline-block;
			font-size: 30px;
			line-height: 160px;
			text-align: center;
			color: #fff;
		}
	::v-deep .el-dialog__header{
		text-align: center;
	}
	 ::v-deep .avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 160px;
		height: 160px;
		line-height: 160px;
		text-align: center;
	}
	.avatar {
		width: 160px;
		height: 160px;
		display: block;
	}
	.up-load--text>span.upload-memo{
		display: inline-block;
		width: 70px;
		text-align: right;
		margin-right: 10px;
	}
	nav {
		overflow: hidden;
		padding: 10px 0;
		background: #fff;
		margin-bottom: 10px;
		border-bottom: 1px solid #eeeeee;
		li {
			float: left;
			&:nth-child(1) {
				width: 45%;
			}
			&:nth-child(2) {
				width: 10%;
			}
			&:nth-child(3) {
				width: 10%;
			}
			&:nth-child(4) {
				width: 10%;
			}
			&:nth-child(5) {
				width: 15%;
			}
			&:nth-child(6) {
				width: 10%;
			}
		}
	}
	.list {
		.item {
			margin-bottom: 20px;
			border: 1px solid #eeeeee;
			border-top: 0;
			.head {
				padding: 8px 10px;
				background: #f7f7f7;
				font-size: 12px;

				.create-time {
					margin-right: 10px;
				}

				border-bottom: 1px solid #eeeeee;
				a {
					margin: 0 10px 0 20px;
				}

				.order-type {
					margin-left: 30px;
				}
			}
		}
		ul {
			background-color: #fff;
			padding: 10px;
			overflow: hidden;
			li {
				float: left;
				line-height: 60px;
				&:nth-child(1) {
					width: 45%;
					line-height: inherit;
					.img-wrap {
						width: 60px;
						height: 60px;
						float: left;
						margin-right: 10px;
						cursor: pointer;
					}
					.info-wrap {
						margin-left: 70px;
						h5 {
							font-weight: normal;
							font-size: $ns-font-size-base;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
							margin-right: 10px;
							display: inline-block;
							cursor: pointer;
							&:hover {
								color: $base-color;
							}
						}
						span {
							font-size: $ns-font-size-sm;
							color: #9a9a9a;
						}
					}
				}
				&:nth-child(2) {
					width: 10%;
				}
				&:nth-child(3) {
					width: 10%;
				}
				&:nth-child(4) {
					width: 10%;
				}
				&:nth-child(5) {
					width: 15%;
					line-height: 30px;
					a {
						display: block;
					}
				}
				&:nth-child(6) {
					width: 10%;
					line-height: initial;
					button {
						margin-left: 0;
						margin-bottom: 10px;
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
	.empty-wrap {
		text-align: center;
		padding: 10px;
	}
}	
	
	
	
</style>
